import React, { useState } from 'react'
import Tools from '../../../components/Tools/Tools'
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import ManageDisbursmentHeader from "../../../components/Disbursment/ManageDisbursmentHeader";
import BudgetAllocationList from '../../../components/Multi/BudgetAllocation/BudgetAllocationList';
import { useNavigate, useParams } from 'react-router-dom';
const AllocateListPage = () => {
    const navigate = useNavigate();
    // const { authData } = useSelector((state) => state.authData);
    const [key, setKey] = useState('list');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');

    const {listType} = useParams();
    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
    };

    const handleTabSelect = (key) => {
        if (key == 'approvalList') {
            navigate('/allocate-budget-approval');
        }
    };

    return (
        <div>
            <Tools />
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} type="Allocate Budgets" />
            <div className='scheme p-3'>
                <div className='scheme p-2'>
                {
                    (listType && listType !== '') ? <div className='all_tabs'>
                        <Tabs
                            defaultActiveKey="Allocate Budgets"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={key}
                            onSelect={handleTabSelect}
                        >
                            <Tab eventKey="approvalList" title="Budget Limit Approval & Allotment" className='p-2'></Tab>
                            <Tab eventKey="list" title="Child Budget Limit Allocations" className='p-2'>
                                <BudgetAllocationList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr} approval={true} />
                            </Tab>
                        </Tabs>
                    </div> : 
                    <><BudgetAllocationList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr} approval={true} /></>
                }
                </div>

            </div>
        </div>
    )
}
export default AllocateListPage
