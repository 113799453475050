import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useLocation } from "react-router-dom";
import { getActivity } from '../../../helper/Api';
import { BiSolidEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const ActivityList = (filterDate) => {
    const { authData } = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let { state } = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);
    const [vendor, setVendorData] = useState('');

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Activity',
            selector: (row) => row.activity,
            sortable: true,
            // width: "150px",
            right: false
        },
        {
            name: 'Status',
            selector: (row) => row.status === "1" ? "Active" : "Inactive",
            sortable: true,
            // width: "150px",
            right: false
        },
    ];

    if(authData.user.department_id == '1' && authData.user.user_type == 'l3'){
        columns.push({
            name: 'Edit / Update',
            width: "200px",
            selector: (row) => {
                return <>
                    {
                        (authData.user.user_type === 'l1') ? <Link to={`/department/edit-activity/${row.id}`}><BiSolidEdit /></Link> : ''
                    }
                </>
            },
            center: true,
        });
    }

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const handleFilterClick = async () => {
        // await fetchBeneficiary(schemeId)
        setData([]);
        await fetchActivity()
    };
    const clearFilter = async () => {
        setFromDate(''); // Clear fromDate
        setToDate('');
        await fetchActivity()
        // await fetchBeneficiary(schemeId)
    };

    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setData(resp.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    // console.log('fdate', filterDate.fromDate);
    useEffect(() => {
        fetchActivity();
    }, [filterDate.searchStr])



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default ActivityList
