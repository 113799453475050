import React, {useEffect, useState, useRef} from 'react';
import Tools from '../../components/Tools/Tools';
import {useParams} from 'react-router-dom';
import ViewInvoiceNew from '../../components/AddVoucher/ViewInvoice';
import { get_payment_invoices } from '../../helper/Api';

const ViewInvoice = () => {
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const {id} = useParams();
    const isLoaded = useRef(false);
    const fetchInvoices = async () => {
        try {
            const invoices_response = await get_payment_invoices({
                user_type: 'L1',
                from_date: '',
                to_date: '',
                isAll: false,
                dateColumn: '',
                search_str: '__fms__required',
                id: id
            });
            if (invoices_response.data.status) {
                setInvoiceDetails(invoices_response.data.list[0]);
                isLoaded.current = true;
            }
        } catch (error) {
            // dispatch(GET_P_INVOICES_FAIL('something went wrong'))
        }
    }

    useEffect(() => {
        fetchInvoices();
    }, [id])
    

    return (
        <div>
            <Tools/>
            <div className='scheme p-2'>
                {
                   isLoaded.current && <ViewInvoiceNew invoice={invoiceDetails}/>
                }
                
            </div>
        </div>
    )
}


export default ViewInvoice
