import React, {useEffect, useState} from 'react'
import "./FundInvoice.css";
import Tools from '../../../components/Tools/Tools';
import FundReleaseHeader from './FundReleaseHeader';
import {Tab, Tabs} from 'react-bootstrap';
import InvoiceList from '../../../components/FundRelease/InvoiceList';
import PaymentInvoiceList from '../../../components/FundRelease/PaymentInvoiceList';
import {Link, useLocation, useParams,useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";


const FundInvoice = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedInvoice, setSelectednvoice] = useState([])
    const [selectedPaymentInvoice, setSelectedPaymentInvoice] = useState([])
    const [key, setKey] = useState('InvoiceList');
    const searchParams = new URLSearchParams(location.search);
    const active = searchParams.get('active');
    const pending=searchParams.get('pending');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const {authData} = useSelector((state) => state.authData);
    const [searchStr, setToSearchStr] = useState('');
    const [heading, setHeading] = useState('Invoice Approve');
    const [dateColumn, setDateColumn] = useState('voucher_date');

    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);

    };
    const handleTabSelect = (key) => {
        if (key == 'paymentStatus') {
            setHeading("Invoice Payment");
            navigate('/fund-invoice-list?pending=true');
        } else if(key == 'InvoiceList') {
            setHeading("Invoice Approval");
            navigate('/fund-invoice-list?pending=false');
        } else if (key == 'Invoice-Service') {
            navigate('/invoices?list=true')
        }
    };
    useEffect(() => {
        if (pending == 'true') {
            setHeading("Invoice Payment");
            navigate('/fund-invoice-list?pending=true');
            setKey('paymentStatus');
        } else {
            setHeading("Invoice Approval");
            navigate('/fund-invoice-list?pending=false');
            setKey('InvoiceList');
        }
    }, [pending])


    // const download_xml_as_zip_file = () => {

    //     const apiURL = 'https://devapi.uatesting.in/schemes/download_xml_as_zip';

    //     const link = document.createElement('a');
    //     link.href = apiURL;
    //     link.target = '_blank'; // Open the API URL in a new tab/window
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // }

    // const tabChangeLink = (link) => {
    //     console.log(link);
    //     navigate(link);
    // }

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
        <div>
            <Tools/>
            <FundReleaseHeader selectedInvoice={selectedInvoice} setSelectednvoice={setSelectednvoice}
                               selectedPaymentInvoice={selectedPaymentInvoice} activeTab={key}
                               onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumn={dateColumn} searchStr={searchStr} heading={heading}/>
            <div className='scheme p-2'>
                <div className="d-flex">
                    {/* <button type="button" onClick={download_xml_as_zip_file}
                            className='btn btn-md btn-primary m-2'>Download
                        XML
                    </button> */}
                    {authData.user.user_type != 'l1' ?
                        <label className="switch">
                            <input className="switch-input" type="checkbox" checked={isChecked}
                                   onChange={handleCheckboxChange}/>
                            <span className="switch-label" data-on="All" data-off="Pending"/>
                            <span className="switch-handle"/>
                        </label> : ''
                    }

                </div>

                <div className='all_tabs'>
                    <Tabs
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={key}
                        onSelect={handleTabSelect}
                    >
                        {<Tab eventKey="InvoiceList" title="Invoice Approval" className='p-2'>
                            <InvoiceList selectedInvoice={selectedInvoice} setSelectednvoice={setSelectednvoice}
                                         fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} isChecked={isChecked}/>
                        </Tab>}
                        {<Tab eventKey="paymentStatus" title="Invoice Payment" className='p-2'>
                            <PaymentInvoiceList selectedPaymentInvoice={selectedPaymentInvoice}
                                                setSelectedPaymentInvoice={setSelectedPaymentInvoice}
                                                fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} isChecked={isChecked}/>
                        </Tab>}
                        <Tab eventKey="Invoice-Service" title="Vendor/Contract/Invoice/Service Provider Payments" className='p-2'></Tab>
                        <Tab eventKey="Penalty" title="Penalties Approval" className='p-2'></Tab>
                        <Tab eventKey="List-Penalty" title="Penalty Payments" className='p-2'></Tab>
                        <Tab eventKey="Withheld" title="Withheld Amount Payment" className='p-2'></Tab>
                        <Tab eventKey="List-Withheld" title="Withheld Amount Payments" className='p-2'></Tab>
                    </Tabs>
                </div>
                <div>
                    {key == 'InvoiceList' ? <>
                        {selectedInvoice.length > 0 ? (
                            <Link to="/fund-approval" state={{data: selectedInvoice}}>
                                <button type="button" className="btn btn-primary">Proceed for Approval</button>
                            </Link>
                        ) : (
                            <button type="button" disabled={true} className="btn btn-primary">Proceed for
                                Approval</button>
                        )}
                    </> : <>
                        {selectedPaymentInvoice.length > 0 ? (
                            <Link to="/fund-proceed-for-payment" state={{data: selectedPaymentInvoice}}>
                                <button type="button" className="btn btn-primary">Proceed for Payment</button>
                            </Link>
                        ) : (
                            <button type="button" disabled={true} className="btn btn-primary">Proceed for
                                Payment</button>
                        )}
                    </>}
                </div>
            </div>
        </div>
    )
}

export default FundInvoice
