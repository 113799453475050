import React, { useEffect, useState } from 'react'
import { get_departments } from '../../../helper/Api';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { GET_DEPARTMENT_FAIL, GET_DEPARTMENT_START, GET_DEPARTMENT_SUCCESS } from '../../../RTK/Slices/DepartmentSlice';
import { FaRegEdit } from "react-icons/fa"
import { Link } from 'react-router-dom';
import './ManageDepartment.css';

const ManageDepartment = ({searchStr, tier}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { department, loading } = useSelector((state) => state.department);
  const { authData } = useSelector((state) => state.authData);
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState(department);
  const fetchDepartments = async () => {
    dispatch(GET_DEPARTMENT_START());
    try {
      const departments = await get_departments({ department_level: tier, searchStr: searchStr, parent_department_id: authData.user.department_id != 1 ? authData.user.department_id : null });
      if (departments.data.status) {
        dispatch(GET_DEPARTMENT_SUCCESS(departments.data.result))
        setDepartments(departments.data.result);
      } else {
        dispatch(GET_DEPARTMENT_FAIL(departments.data.message))
      }
    } catch (error) {
      dispatch(GET_DEPARTMENT_FAIL('something went wrong'))
    }
  }

  useEffect(() => {
    fetchDepartments();
  }, [dispatch, searchStr, tier])

  useEffect(() => {
    setDepartments(department)
  }, [department]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px"
      },
    },
  };


  const columns = [
    {
      name: 'Code',
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      wrap: true
    },
    {
      name: 'Department Level',
      selector: (row) => row.nomiclature,
      sortable: true,
      wrap: true,
      // width:"100px"
    },
    {
      name: 'Tier',
      selector: (row) => row.department_level,
      sortable: true,
      wrap: true,
      // width:"100px"
    },
    {
      name: 'Parent Dept. Name',
      selector: (row) => row.parent_department_name,
      sortable: true,
      wrap: true,
      // width:"100px"
    },
    {
      name: 'Parent Dept. Tier',
      selector: (row) => row.parent_department_level,
      sortable: true,
      wrap: true,
      // width:"100px"
    },
    
    {
      name: 'Action',
      width: "200px",
      selector: (row) => {
        return (
          <div className='user_action'>
            <span className='pr-4'> {row.department_level !== 'T1' ? <Link to={`/edit-department/${row.id}`}><FaRegEdit /></Link> : ''}</span>
          </div>
        );
      },
      sortable: true,
    }
  ];
  const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setRowsPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };
  return (
    <div>
      <DataTable
        customStyles={customStyles}
        className="dataTables_wrapper"
        progressPending={loading}
        columns={columns}
        data={departments}
        fixedHeader
        fixedHeaderScrollHeight="600px"
        pagination
        paginationPerPage={rowsPerPage}
        paginationTotalRows={departments.length}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default ManageDepartment
