import React, {useState} from 'react'
import Tools from '../../components/Tools/Tools'
import './Schemes.css';
import { SchemesList } from '../../components/BudgetAllocation/Schemes/SchemesList';
import { useSelector } from 'react-redux';
import {Tab, Tabs} from "react-bootstrap";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";

const Schemes = () => {
    const {authData} = useSelector((state) => state.authData);
    const [key, setKey] = useState('list');

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [fyId, setFyId] = useState(2);
    const [departmentId, setDepartmentId] = useState('');
    const [tier, setTier] = useState('');


    const handleDateChange = (start, end, dateColumn, searchStr, fyId, tier, departmentId) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
        setFyId(fyId);
        setTier(tier);
        setDepartmentId(departmentId);
    };

  return (
    <div>
        <Tools></Tools>
        <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} fyId={fyId} type="Schemes" />
        <div className='scheme p-3'>
            <div className='scheme p-2'>
                <div className='all_tabs'>
                    <Tabs
                        // defaultActiveKey="InvoiceList"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="list" title="Schemes List" className='p-2'>
                            <SchemesList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr} fyId={fyId} tier={tier} departmentId={departmentId}/>
                        </Tab>
                        {authData.user.user_type != 'l1' ?
                            <Tab eventKey="actionList" title="Pending Action" className='p-2'>
                                <SchemesList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr} fyId={fyId} tier={tier} departmentId={departmentId}/>
                            </Tab> : ''}
                    </Tabs>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Schemes
