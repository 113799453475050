import React, {useState} from 'react'
import {Col, Container, Form, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {downloadExcel} from "../../../helper/Api";

const FundReleaseHeader = ({selectedInvoice, activeTab, selectedPaymentInvoice, onDateChange, heading}) => {

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [dateColumn, setDateColumn] = useState('voucher_date');


    const handleInput = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'fromDate') {
            setFromDate(value);
        }else if(name === 'dateColumn') {
            setDateColumn(value);
        } else if(name === 'search_str') {
            setToSearchStr(value);
        } else {
            setToDate(value);
        }
    }

    const handleFilterClick = () => {
        if (onDateChange) {
            onDateChange(fromDate, toDate, dateColumn, searchStr);
        }
    };
    const clearFilter = () => {
        setFromDate(''); // Clear fromDate
        setToDate('');
        setDateColumn('voucher_date');
        setToSearchStr('');
        if (onDateChange) {
            onDateChange('', '', '', '');
        }
    };

    const download_xml_as_zip_file = async () => {

        let apiURL = 'schemes/download_xml_as_zip';
        if (activeTab == 'InvoiceList') {
            apiURL = 'sanctions_order/download_invoices_excel';
        } else {
            apiURL = 'sanctions_order/download_payment_invoices_excel';
        }
        try {
            const response = await downloadExcel(apiURL, {from_date: fromDate, to_date: toDate, search_str: searchStr, dateColumn: dateColumn});
            if (response?.data?.status) {
                const aEle = document.createElement('a');
                aEle.href = response.data.excel_url;  // TODO Add complete File url here
                aEle.target = '_blank'; // Open the API URL in a new tab/window
                // document.body.appendChild(aEle);
                aEle.click();
                // document.body.removeChild(aEle);
            }


        } catch (e) {
            console.log('Something went wrong', e)
        }
    }
    return (
        <div className='addNewScheme'>
            <div className="add_new_user">
                {/* <div className="d-flex"> */}
                    <Container fluid>
                        <Row>
                            <Col sm={6}>
                                <h4>{heading}</h4>
                            </Col>

                        </Row>
                    </Container>

                {/* </div> */}
                <div>
                    <button type="button" onClick={download_xml_as_zip_file}
                            className='btn btn-md btn-primary m-2' style={{width: '200px'}}>Download Excel
                    </button>
                    {/* {activeTab == 'InvoiceList' ? <>
                        {selectedInvoice.length > 0 ? (
                            <Link to="/fund-approval" state={{data: selectedInvoice}}>
                                <button type="button" className="btn btn-primary">Proceed for Approval</button>
                            </Link>
                        ) : (
                            <button type="button" disabled={true} className="btn btn-primary">Proceed for
                                Approval</button>
                        )}
                    </> : <>
                        {selectedPaymentInvoice.length > 0 ? (
                            <Link to="/fund-proceed-for-payment" state={{data: selectedPaymentInvoice}}>
                                <button type="button" className="btn btn-primary">Proceed for Payment</button>
                            </Link>
                        ) : (
                            <button type="button" disabled={true} className="btn btn-primary">Proceed for
                                Payment</button>
                        )}
                    </>} */}
                </div>
            </div>

            <div className="add_new_user mt-2">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h5>Filters</h5>
                        </Col>
                            <Col sm={4}>
                                <Form.Group className="" controlId="">
                                    <select name="dateColumn" className='form-control mb-2' onChange={handleInput}>
                                        <option value="voucher_date" selected={dateColumn === 'voucher_date'}>Voucher Date</option>
                                        <option value="invoice_date" selected={dateColumn === 'invoice_date'}>Invoice Date</option>
                                        <option value="sanction_order_date" selected={dateColumn === 'sanction_order_date'}>Payment Sanction Order Date</option>
                                        {/* <option value="invoice_payment.added_on" selected={dateColumn === 'invoice_payment.added_on'}>Transaction Success Date</option> */}
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                            <Form.Group className="" controlId="">
                                <Form.Control type="date" name="fromDate"
                                                value={fromDate}
                                                onChange={handleInput}/>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="" controlId="">
                                    <Form.Control type="date" name="toDate"
                                                value={toDate}
                                                onChange={handleInput}/>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="">
                                    <Form.Control type="text" name="search_str" onChange={handleInput} value={searchStr} placeholder='Search By Beneficary Name, Invoice No, Payment Sanction No or voucher no'/>
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <div className="d-flex mt-3">
                                    <div className="text-start mx-2">
                                        <button type="button" className="btn btn-outline-primary" onClick={clearFilter}>Clear</button>
                                    </div>
                                    <div className="text-start">
                                        <button type="button" className="btn btn-primary" onClick={handleFilterClick}>Filter</button>
                                    </div>
                                </div>
                            </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default FundReleaseHeader
